import React from 'react';
import Link from 'next/link';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineUser, AiOutlineSearch } from 'react-icons/ai';
import classNames from 'classnames';
import dynamic from "next/dynamic";
import { useCategoriesWrapper } from '../../service/category';
import CategoryNav from '../category_nav';
import CartNav from '../cart_nav';
import NotificationNav from '../notification_nav';
import { useUser, useSignedIn, useTfaPassed } from '../../hooks/user';
import styles from '../../styles/top_nav.module.scss';
import SearchForm from '../search_form';
import {useRegionLinkPrefix} from "../../hooks/region";

const RegionNav = dynamic(
  () => import('../region_nav'),
  { ssr: false }
);
const RegionLogoIndicator = dynamic(
  () => import('../region_logo_indicator'),
  { ssr: false }
);

export default function TopNav() {
  const signedIn = useSignedIn();
  const tfaPassed = useTfaPassed();
  const categories = useCategoriesWrapper();
  const user = useUser();
  const regionLinkPrefix = useRegionLinkPrefix();
  
  const [showSearchBar, setShowSearchBar] = React.useState(false);

  const searchBarClassName = classNames(styles.searchBar, 'bg-light', {
    'd-none': !showSearchBar
  });

  const toggleSearchBar = () => setShowSearchBar(!showSearchBar);

  return (<>
    <Navbar collapseOnSelect expand="md" bg="dark" variant="dark" className="bg-primary-gradient">
      <div className="container">
        <Link href={regionLinkPrefix} className="navbar-brand">

          <img src="/images/indo4ward.svg" alt="indo4ward logo" className="nav-logo" />
          <RegionLogoIndicator />

        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="text-center text-md-left">
          <Nav>
            {categories ? (
              <CategoryNav
                categories={categories}
                className="d-none d-md-block"
              />
            ) : (
              <Nav.Item className="text-uppercase">
                <Link href="/categories" className="nav-link">
                  Categories
                </Link>
              </Nav.Item>
            )}
          </Nav>
          <Nav className="ml-auto">
            <Nav.Item className="d-block d-md-none text-uppercase">
              <Link href="/categories" className="nav-link">
                Categories
              </Link>
            </Nav.Item>
            
            <Nav.Item className="mr-2">
              <Link href="/merchants" className="nav-link text-uppercase">
                Our Merchants
              </Link>
            </Nav.Item>
            
            {signedIn ? (
              <>
                <Nav.Item className="mr-2">
                  <Link href="/external_quotations" className="nav-link text-uppercase">
                    Quotations
                  </Link>
                </Nav.Item>

                {tfaPassed && (
                  <>
                    <CartNav />

                    <Nav.Item className="d-block d-md-none text-uppercase">
                      <Link href="/cart" className="nav-link">
                        Cart
                      </Link>
                    </Nav.Item>

                    <NotificationNav />
                    <Nav.Item className="d-block d-md-none text-uppercase">
                      <Link href="/account/notifications" className="nav-link">
                        Notifications
                      </Link>
                    </Nav.Item>
                  </>
                )}
                

                <Nav.Item className="mr-4 align-self-center d-none d-md-block">
                  <button className={`nav-link ${styles.searchIcon}`} onClick={toggleSearchBar}>
                    <AiOutlineSearch />
                  </button>
                </Nav.Item>
                
                <Nav.Item className="mr-2">
                  {user ? (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="light"
                      >
                        <AiOutlineUser />
                        {user.firstName}
                      </Dropdown.Toggle>

                      <Dropdown.Menu 
                        align="end">
                        <Link href="/account/profile" passHref={true} legacyBehavior>
                          <Dropdown.Item>
                            Account Details
                          </Dropdown.Item>
                        </Link>
                        <Link href="/account/orders" passHref={true} legacyBehavior>
                          <Dropdown.Item>
                            My Orders
                          </Dropdown.Item>
                        </Link>
                        <Link href="/sign_out" passHref={true} legacyBehavior>
                          <Dropdown.Item>
                            Sign Out
                          </Dropdown.Item>
                        </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <Link href="/sign_out" className="nav-link text-uppercase">Sign Out</Link>
                  )}
                </Nav.Item>
              </>
            ) : (
              <React.Fragment>
                <Nav.Item className="mr-2">
                  <Link href="/sign_in" className="nav-link text-uppercase">
                    Sign In
                  </Link>
                </Nav.Item>
                <Nav.Item className="mr-2">
                  <Link href="/sign_up" className="nav-link btn btn-dark-custom text-uppercase">
                    Sign Up
                  </Link>
                </Nav.Item>
              </React.Fragment>
            )}
            
            <Nav.Item>
              <RegionNav />
            </Nav.Item>
            <Nav.Item className="d-block d-md-none mt-2">
              <SearchForm />
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
    <div className={searchBarClassName}>
      <div className="container">
        <SearchForm />
      </div>
    </div>
  </>);
}