import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from '../store/actions'
import {RootState} from "../store/reducers/reducers";

export function useUser() {
  const {profile, signedIn, tfaPassed} = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!profile && signedIn && tfaPassed) {
      dispatch(actions.user.fetchMe());
    }
  }, [profile, signedIn, tfaPassed])

  return profile;
}

export function useSignedIn() {
	return useSelector((state: RootState) => state.user.signedIn);
}

export function useTfaPassed() {
	return useSelector((state: RootState) => state.user.signedIn && state.user.tfaPassed);
}