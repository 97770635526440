import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {actions} from '../store/actions'
import {RootState} from "../store/reducers/reducers";
import {AnyAction} from "redux";

function useAction(action: AnyAction) {
	const dispatch = useDispatch()
	return useCallback(
		() => dispatch(action),
		[dispatch]
	)
}

export default {
	useOnLogin() {
		const action = actions.user.signIn()
		return useAction(action)
	},
	useSignOut() {
		const dispatch = useDispatch()
		return useCallback(
			() => {
				dispatch(actions.user.signOutAsync())
				dispatch(actions.region.setPreferredRegion(null))
			},
			[dispatch]
		)
	},
	useIsLoggedIn() {
		const signedIn = useSelector((state: RootState) => state.user.signedIn)
		return !!signedIn
	},
	useHasCompletedTfa() {
		const tfaPassed = useSelector((state: RootState) => state.user.tfaPassed)
		return !!tfaPassed
	}
}