import React from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../store/reducers/reducers";
import {QuotationItem, QuotationItemSources, QuotationItemStatuses} from "../types/models/quotation_item";
import {CartItem, CartItemProductForCart} from "../types/models/cart_item";
import {groupBy, property} from "lodash";

export type CartAndQuotationItemWrapper = {
  type: 'quotation_internal';
  isQuotation: true;
  item: QuotationItem<QuotationItemStatuses, QuotationItemSources.internal>;
  product: CartItemProductForCart;
} | {
  type: 'quotation_external';
  isQuotation: true;
  item: QuotationItem<QuotationItemStatuses, QuotationItemSources.external>;
  product: undefined;
} | {
  type: 'cart';
  isQuotation: false;
  item: CartItem;
  product: CartItemProductForCart;
};

export interface UseCartAndQuotationItemsResult {
  isLoaded: boolean;
  items: CartAndQuotationItemWrapper[];
  declinedQuotationItems: QuotationItem[];
}

export default function useCartAndQuotationItems(): UseCartAndQuotationItemsResult {
  const cartItems = useSelector((state: RootState) => state.cart.data);
  const cartItemProducts = useSelector((state: RootState) => state.cart.productById)
  const quotationItems = useSelector((state: RootState) => state.quotationItem.data);
  const quotationItemProducts = useSelector((state: RootState) => state.quotationItem.productById)
  const isCartItemsLoaded = useSelector((state: RootState) => state.cart.dataLoaded);
  const isQuotationItemsLoaded = useSelector((state: RootState) => state.quotationItem.dataLoaded);

  const combinedItems = React.useMemo(() => {
    const wrappedItems: CartAndQuotationItemWrapper[] = [];
    wrappedItems.push(...cartItems.map(ci => {
      const item: CartAndQuotationItemWrapper = {
        type: 'cart',
        isQuotation: false,
        item: ci,
        product: cartItemProducts[ci.productId]
      };
      return item;
    }));

    const quotationItemsByStatus = groupBy(quotationItems, property('status'));
    const addedQuotationItemIds = new Set<string>();
    const resolvedQuotationItems = quotationItemsByStatus[QuotationItemStatuses.resolved] ?? [];
    wrappedItems.push(...resolvedQuotationItems.map((qi: QuotationItem) => {
      let wrappedItem: CartAndQuotationItemWrapper;
      addedQuotationItemIds.add(qi._id);
      if (qi.productSource === QuotationItemSources.internal) {
        const internalItem = qi as QuotationItem<QuotationItemStatuses, QuotationItemSources.internal>;
        wrappedItem = {
          type: 'quotation_internal',
          isQuotation: true,
          item: internalItem,
          product: quotationItemProducts[internalItem.productSourceData.productId]
        };
      } else {
        wrappedItem = {
          type: 'quotation_external',
          isQuotation: true,
          item: qi as QuotationItem<QuotationItemStatuses, QuotationItemSources.external>,
          product: undefined
        };
      }
      return wrappedItem;
    }));

    const pendingQuotationItemsBySource = groupBy(
      quotationItems
        .filter(quotationItem => !addedQuotationItemIds.has(quotationItem._id)),
      property('productSource')
    );
    const pendingInternalQuotationItems = pendingQuotationItemsBySource[QuotationItemSources.internal] ?? [];
    wrappedItems.push(...pendingInternalQuotationItems.map((qi: QuotationItem) => {
      const internalItem = qi as QuotationItem<QuotationItemStatuses, QuotationItemSources.internal>;
      const wrappedItem: CartAndQuotationItemWrapper = {
        type: 'quotation_internal',
        isQuotation: true,
        item: internalItem,
        product: quotationItemProducts[internalItem.productSourceData.productId]
      };
      return wrappedItem;
    }));

    const pendingExternalQuotationItems = pendingQuotationItemsBySource[QuotationItemSources.external] ?? [];
    wrappedItems.push(...pendingExternalQuotationItems.map((qi: QuotationItem) => {
      const wrappedItem: CartAndQuotationItemWrapper = {
        type: 'quotation_external',
        isQuotation: true,
        item: qi as QuotationItem<QuotationItemStatuses, QuotationItemSources.external>,
        product: undefined
      };
      return wrappedItem;
    }));

    return {
      items: wrappedItems,
      declinedQuotationItems: quotationItemsByStatus[QuotationItemStatuses.declined] ?? []
    };
  }, [cartItems, quotationItems]);

  return {
    ...combinedItems,
    isLoaded: isCartItemsLoaded && isQuotationItemsLoaded
  };
}