import React from "react";

export function genericTextChange(setter: React.Dispatch<React.SetStateAction<string>>) {
	return (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => setter(e.target.value);
}

export function genericRadioChange(setter: React.Dispatch<React.SetStateAction<string>>) {
	return (e: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, value} = e.target;
		if (checked) {
			setter(value);
		}
	};
}

export function genericRadioChangeWithValue<T>(setter: React.Dispatch<React.SetStateAction<T>>, checkedValue: T) {
	return (e: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = e.target;
		if (checked) {
			setter(checkedValue);
		}
	};
}

export function genericCheckboxChange(setter: React.Dispatch<React.SetStateAction<boolean>> | ((value: boolean) => void)) {
	return (e: React.ChangeEvent<HTMLInputElement>) => setter(e.target.checked);
}

export function genericNumberChange(setter: React.Dispatch<React.SetStateAction<string>>) {
	return (e: React.ChangeEvent<HTMLInputElement>) => {
		setter(e.target.value.replace(/\D/, ''));
	};
}

export function wrapFieldErrors<FE, T extends Array<any>, U>(
	fieldErrors: FE,
	fieldErrorsSetter: React.Dispatch<React.SetStateAction<FE>>,
	fieldAttr: keyof FE,
	handler: (...args: T) => U): (...args: T) => U {
	return (...args: T) => {
		const copy = Object.assign({}, fieldErrors);
		delete copy[fieldAttr];
		fieldErrorsSetter(copy);

		return handler(...args);
	};
}