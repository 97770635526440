import React from "react";
import Head from 'next/head';

interface HeadTitleProps {
	title?: string;
	children?: React.ReactNode;
}

export default function HeadTitle({ title, children }: HeadTitleProps) {
	let finalTitle = 'Indo4ward';
	if (title) {
		finalTitle = `${title} | Indo4ward`;
	}

	return <Head>
		<title>{finalTitle}</title>
		{children}
	</Head>;
}