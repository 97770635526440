import Router from 'next/router';
import qs from 'querystring';
import Api from '../service/api';

const NO_PROCESSING_HOSTNAMES = [
	'www.orami.co.id', 
	'orami.co.id',
	'www.tokopedia.com', 
	'tokopedia.com', 
	'm.tokopedia.com', 
	'tokopedia.link',
	'bnc.lt'
]

export function isSearchSupported(url: URL): boolean {
	if (NO_PROCESSING_HOSTNAMES.indexOf(url.hostname) !== -1) {
		return true
	}

	if (url.hostname === 'ta.tokopedia.com' && url.searchParams.has('r')) {
		const tokopediaLink = url.searchParams.get('r')
		if (!tokopediaLink) {
			return false;
		}

		try {
			const tokopediaLinkURL = new URL(tokopediaLink);
			return isSearchSupported(tokopediaLinkURL);
		} catch (error) {
			return false;
		}
	}
	return false;
}

function postProcess(url: URL) {
	if (url.hostname === 'ta.tokopedia.com') {
		// handle tokopedia promoted listing
		return url.searchParams.get('r')
	} else if (url.hostname === 'm.tokopedia.com') {
		url.hostname = 'tokopedia.com'
	}
	return url.toString()
}

export function searchSupportedUrl(url: URL, regionLinkPrefix='') {
	const finalUrl = postProcess(url)
	if (!finalUrl) {
		Router.push('/404');
		return;
	}

	Api.product.searchExternal(finalUrl)
		.then((response) => {
			const {pending} = response.data;
			if (pending) {
				const {link} = response.data;
				const urlSearchParams = new URLSearchParams()
				urlSearchParams.set('productUrl', link)
				Router.push(`/pending_product?${urlSearchParams.toString()}`)
			} else {
				// go to product page
				const {product} = response.data;
				Router.push(`${regionLinkPrefix}/product/${product.paramName}`)
			}
		});
}

export const handleSearch = (searchText: string, regionLinkPrefix='') => {
	let url = null
	try {
		url = new URL(searchText)
	} catch (error) {
		const urlSearchParams = new URLSearchParams()
		urlSearchParams.set('text', searchText)
		Router.push(`${regionLinkPrefix}/product/search?${urlSearchParams.toString()}`)
		return;
	}

	if (!isSearchSupported(url)) {
		const querystring = qs.stringify({
			requestLink: url.toString()
		});
		Router.push(`/external_quotations?${querystring}`);
		return;
	}

	searchSupportedUrl(url, regionLinkPrefix);
}