export const GROUP_TYPE_ORDER = 1
export const NOTIFICATION_TYPE_PAYMENT_RECEIVED = 11
export const NOTIFICATION_TYPE_ORDER_PURCHASED = 12
export const NOTIFICATION_TYPE_ORDER_AT_WAREHOUSE = 13
export const NOTIFICATION_TYPE_ORDER_IN_TRANSIT = 14
export const NOTIFICATION_TYPE_ORDER_PROMPT_RECEIPT = 15
export const NOTIFICATION_TYPE_ORDER_DISPUTE_RESPOND = 16

export const GROUP_TYPE_QUOTATION_ITEM = 2;
export const NOTIFICATION_TYPE_QUOTATION_ITEM_RESOLVED = 21;
export const NOTIFICATION_TYPE_QUOTATION_ITEM_DECLINED = 22;