import {QuotationItemStatuses} from "../../types/models/quotation_item";

interface QuotationItemStatusBadgeProps {
  status: QuotationItemStatuses;
}

export default function QuotationItemStatusBadgeComponent(props: QuotationItemStatusBadgeProps) {
  switch (props.status) {
    case QuotationItemStatuses.pending:
      return <span className="badge badge-secondary" style={{
        opacity: '.65'
      }}>Pending Price Review</span>;
    case QuotationItemStatuses.resolved:
      return <span className="badge badge-success">Resolved</span>;
    case QuotationItemStatuses.declined:
      return <span className="badge badge-danger">Decline</span>;
    default:
      return null;
  }
}